import { Alert, AlertTitle } from "@mui/material";

function UrlError() {
  return (
    <Alert severity="error">
      <AlertTitle>Page Not Found :(</AlertTitle>
      The page you requested was not found
    </Alert>
  );
}

export default UrlError;

import { Box, LinearProgress, Skeleton } from "@mui/material";

function Loading() {
  return (
    <Box>
      <LinearProgress />
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </Box>
  );
}

export default Loading;

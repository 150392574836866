import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, Drawer, Link, TextField } from "@mui/material";
import {
  DarkMode,
  LightMode,
  SchoolSharp,
  Search,
  Settings,
} from "@mui/icons-material";
import { useContext } from "react";
import Context from "../context/Context";
import { Navigate, useNavigate } from "react-router-dom";

const pages = ["Home", "Features"];

const AppNavBar = () => {
  const context = useContext(Context);
  const settings = [
    "Profile",
    "Account",
    "Dashboard",
    "Logout",
    context.theme === "light" ? <DarkMode /> : <LightMode />,
  ];
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();

  const onSettingsClick = (setting) => {
    if (setting === settings[settings.length - 1]) {
      context.changeTheme();
    } else if (setting === "Profile") {
      navigate("/profile");
    } else if (setting === "Logout") {
      context.setAccessToken(null);
      localStorage.removeItem("accessToken");
      navigate("/signin");
    } else if (setting === "Dashboard") {
      navigate("/dashboard");
    } else if (setting === "Account") {
      navigate("/account-setting");
    }
    setAnchorElUser(null);
  };

  const handlePage = (page) => {
    if (page === "Home") {
      navigate("/");
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar position="static" color="inherit">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Button
              onClick={() => navigate("/")}
              color="inherit"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <SchoolSharp sx={{ mr: 1 }} />
            </Button>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#"
              onClick={() => navigate("/")}
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              EDURA
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => handlePage(page)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
                <MenuItem key="search">
                  <TextField
                    lablel={<Search />}
                    variant="outlined"
                    placeholder="Search"
                  />
                </MenuItem>
              </Drawer>
            </Box>
            <Button
              onClick={() => navigate("/")}
              color="inherit"
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <SchoolSharp sx={{ mr: 1 }} />
            </Button>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#"
              onClick={() => navigate("/")}
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 2,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              EDURA
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => handlePage(page)}
                  sx={{ my: 2, display: "block" }}
                >
                  {page}
                </Button>
              ))}
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" }, mx: 2 }}>
              <TextField
                label={<Search />}
                placeholder="Search"
                variant="outlined"
              />
            </Box>
            <Box sx={{ flexGrow: 0, mx: 1 }}>
              <Tooltip title="Open Navigation Panel">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar></Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => onSettingsClick(setting)}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default AppNavBar;

import React from "react";
import Loading from "./loading/Loading";
import Context from "./context/Context";
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { Container } from "@mui/system";
import Routing from "./routing/Routing";
import AppNavBar from "./header/AppNavBar";
const GuestNavBar = React.lazy(() => import("./header/GuestNavBar"));
const Footer = React.lazy(() => import("./footer/FooterBootstrap"));

function App() {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "light"
  );
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
      ? localStorage.getItem("accessToken")
      : null
  );
  const isMobile = useMediaQuery("(max-width:600px)");
  const changeTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    } else {
      setTheme("light");
      localStorage.setItem("theme", "light");
    }
  };

  const checkAccessToken = () => {
    if (accessToken) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Context.Provider
      value={{
        changeTheme: changeTheme,
        theme: theme,
        isMobile: isMobile,
        accessToken: accessToken,
        setAccessToken: setAccessToken,
        checkAccessToken: checkAccessToken,
      }}
    >
      <ThemeProvider theme={createTheme({ palette: { mode: theme } })}>
        <Container>
          <CssBaseline />
          <React.Suspense fallback={<Loading />}>
            {accessToken ? <AppNavBar /> : <GuestNavBar />}
          </React.Suspense>
          <Routing />
          <React.Suspense fallback={<Loading />}>
            <Footer />
          </React.Suspense>
        </Container>
      </ThemeProvider>
    </Context.Provider>
  );
}
export default App;

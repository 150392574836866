import { formGroupClasses } from "@mui/material";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import UrlError from "../error/UrlError";
import Loading from "../loading/Loading";
const SignIn = React.lazy(() => import("../userManagement/signIn/SingIn"));
const Home = React.lazy(() => import("../home/Home"));
const SignUp = React.lazy(() => import("../userManagement/signup/SignUp"));
const Dashboard = React.lazy(() => import("../dashboard/Dashboard"));
const AccountSettings = React.lazy(() => import("../Account/AccountSettings"));
const Profile = React.lazy(() => import("../Account/Profile"));
const Contact = React.lazy(()=> import("../company/Contact"));

function Routing() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <React.Suspense fallback={<Loading />}>
            <Home />
          </React.Suspense>
        }
      />
      <Route
        path="/dashboard/"
        element={
          <React.Suspense fallback={<Loading />}>
            <Dashboard />
          </React.Suspense>
        }
      />
      <Route
        path="/profile"
        element={
          <React.Suspense fallback={<Loading />}>
            <Profile />
          </React.Suspense>
        }
      />
      <Route
        path="/account-setting"
        element={
          <React.Suspense fallback={<Loading />}>
            <AccountSettings />
          </React.Suspense>
        }
      />
      <Route
        path="/contact"
        element={
          <React.Suspense fallback={<Loading />}>
            <Contact />
          </React.Suspense>
        }
      />
      <Route
        path="/signin/"
        element={
          <React.Suspense fallback={<Loading />}>
            <SignIn />
          </React.Suspense>
        }
      />
      <Route
        path="/signup/"
        element={
          <React.Suspense fallback={<Loading />}>
            <SignUp />
          </React.Suspense>
        }
      />
      <Route
        path="/*"
        element={
          <React.Suspense fallback={<Loading />}>
            <UrlError />
          </React.Suspense>
        }
      />
    </Routes>
  );
}

export default Routing;
